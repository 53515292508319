import React, { useState } from 'react'
import Icon, { IconName } from '@dustin-web/microsite-components/src/icon'
import Button from '@dustin-web/microsite-components/src/button'
import { useTranslations, useWebToggles } from '@dustin-web/microsite-components/src/hooks'
import { icons } from '@dustin-web/microsite-components/src/icon/icons'
import { useGraphql } from '@dustin-web/microsite-data/src/hooks/use-graphql'
import {
  GetBusinessCustomerSettings,
  GetBusinessCustomerSettingsQuery,
  GetBusinessCustomerSettingsResult,
} from '../../graphql/generated/graphql-types'

const FrameAgreementBanner = () => {
  const { translate } = useTranslations()
  const { isToggleEnabled } = useWebToggles()

  const [isToggled] = useState<boolean>(isToggleEnabled('show-myaccount-frame-banner'))
  const [show, setShow] = useState(true)

  const iconName = translate('Frame_Banner_Icon') as IconName
  const buttonText = translate('Frame_Banner_ButtonText')

  const { data } = useGraphql<GetBusinessCustomerSettingsQuery, GetBusinessCustomerSettingsResult>(
    GetBusinessCustomerSettings,
    {}
  )

  if (isToggled && data?.businessCustomerSettings?.showFrameAgreementBanner) {
    return (
      <>
        {show && (
          <div className={`col-12 ${translate('Frame_Banner_Theme')} u-px-0 u-px-md-16`}>
            <div
              className="u-absolute u-cursor-pointer"
              style={{ right: 16, top: 16 }}
              onClick={() => setShow(!show)}
            >
              <Icon name="close-2" className="i-16" />
            </div>
            <div className="container u-m-auto d-flex flex-column flex-md-row align-items-center u-py-24 justify-content-between">
              <div className="d-flex align-items-center col-12 col-md-9 u-px-0">
                {icons[iconName] !== undefined && (
                  <div className="col-3 col-md-1 d-none d-md-flex justify-content-center">
                    <Icon name={iconName} />
                  </div>
                )}
                <div className="u-fs-14 d-flex flex-column col-12 col-md-8">
                  <span className="u-fw-bold">{translate('Frame_Banner_Title')}</span>
                  <span>{translate('Frame_Banner_Content')}</span>
                </div>
              </div>
              {buttonText !== 'frame_banner_buttontext' && (
                <div className="col-12 col-md-3 d-flex justify-content-center u-px-16 u-pt-16 u-pt-md-0">
                  <Button
                    primary
                    text={buttonText}
                    link
                    href={'Frame_Banner_ButtonLink'}
                    className={'col-12 u-mb-0'}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </>
    )
  }
  return null
}

export default FrameAgreementBanner
