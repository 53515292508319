import React, { useState } from 'react'
import Icon from '@dustin-web/microsite-components/src/icon'
import Button from '@dustin-web/microsite-components/src/button'
import { useTranslations, useWebToggles } from '@dustin-web/microsite-components/src/hooks'
import {
  ModalDialog,
  ModalDialogHeader,
  ModalDialogContent,
  ModalDialogFooter,
} from '@dustin-web/microsite-components/src/modal-dialog'
import Link from 'next/link'
import { useGraphqlMutation } from '@dustin-web/microsite-data/src/hooks'
import MutationState from 'src/constants/mutationState'
import {
  CreateSurveyOptInCommand,
  CreateSurveyOptInCommandMutation,
  CreateSurveyOptInCommandMutationVariables,
} from 'src/graphql/generated/graphql-types'
import Spinner from '@dustin-web/microsite-components/src/spinner'

const OptInBanner = () => {
  const { translate } = useTranslations()
  const { isToggleEnabled } = useWebToggles()

  const [isToggled] = useState<boolean>(isToggleEnabled('show-myaccount-opt-in-banner'))
  const [show, setShow] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [state, setState] = useState<MutationState>(MutationState.default)
  const [success, setSuccess] = useState(false)

  const [mutation] = useGraphqlMutation<
    CreateSurveyOptInCommandMutation,
    CreateSurveyOptInCommandMutationVariables
  >(CreateSurveyOptInCommand, {
    onError: () => {
      setState(MutationState.error)
    },
  })

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleAcceptRequest = async () => {
    try {
      setState(MutationState.loading)
      await mutation({
        variables: {
          optedIn: true,
        },
      })

      setSuccess(true)
      setState(MutationState.default)
    } catch (error) {
      setState(MutationState.error)
    }
  }

  if (isToggled) {
    return (
      <>
        {show && (
          <div className={`col-12 u-relative ${translate('Opt_In_Banner_Theme')}`}>
            <div
              className="u-absolute u-cursor-pointer"
              style={{ right: 16, top: 16, zIndex: 2 }}
              onClick={() => setShow(!show)}
            >
              <Icon name="close-2" className="i-16" />
            </div>
            <div className="container u-m-auto d-flex flex-column flex-md-row align-items-center u-py-24 justify-content-between u-px-0">
              <div className="d-flex align-items-center col-12 col-md-9 u-px-0">
                <div className="u-fs-14 d-flex flex-column col-12 col-md-8">
                  <span className="u-fw-bold u-pt-8">{translate('Opt_In_Banner_Title')}</span>
                  <span>{translate('Opt_In_Banner_Content')}</span>
                </div>
              </div>
              <div className="col-12 col-md-3 d-flex justify-content-center u-px-16 u-pt-16 u-pt-md-0">
                <Button
                  primary
                  text={translate('Opt_In_Banner_ButtonText')}
                  className={'col-12 u-mb-0 u-mr-24'}
                  onClick={() => setShowModal(!showModal)}
                />
                <ModalDialog show={showModal} handleClose={() => setShowModal(false)}>
                  <ModalDialogHeader
                    title={
                      !success ? translate('Opt_In_Modal_Title') : translate('Opt_In_Modal_Success')
                    }
                  />
                  <ModalDialogContent>
                    <div className="u-fs-16">
                      {!success ? (
                        <div className="d-flex flex-column u-mb-16 ">
                          <span className="u-pb-8 u-fw-bold">
                            {translate('Opt_In_Modal_HeaderOne')}
                          </span>
                          <span>{translate('Opt_In_Modal_ContentOne')}</span>
                        </div>
                      ) : (
                        <div className="d-flex flex-column u-mb-16 ">
                          <span className="u-pb-8 u-fw-bold">
                            {translate('Opt_In_Modal_HeaderTwo')}
                          </span>
                          <span>{translate('Opt_In_Modal_ContentTwo')}</span>
                        </div>
                      )}
                      {!success && (
                        <>
                          <div className="u-bg-light-gray u-px-16 u-py-16 u-mb-16">
                            <div className="u-mb-8">
                              <span className="u-pb-16 u-fw-bold">
                                {translate('Opt_In_ModalInfo_Title')}
                              </span>
                            </div>
                            <div className="u-mb-8">
                              <Icon name="clock" className="i-16 u-mr-8" />{' '}
                              <span>{translate('Opt_In_ModalInfo_One')}</span>
                            </div>
                            <div className="u-mb-8">
                              <Icon name="headset" className="i-16 u-mr-8" />{' '}
                              <span>{translate('Opt_In_ModalInfo_Two')}</span>
                            </div>
                            <div className="u-mb-8">
                              <Icon name="gift" className="i-16 u-mr-8" />{' '}
                              <span>{translate('Opt_In_ModalInfo_Three')}</span>
                            </div>
                          </div>
                          <div>
                            <span>
                              {translate('Opt_In_Modal_Legal_One')}
                              <Link className="u-px-8" href={'/service/integritetspolicy'}>
                                {translate('Opt_In_Modal_Legal_Two')}
                              </Link>
                              {translate('Opt_In_Modal_Legal_Three')}
                            </span>
                          </div>
                        </>
                      )}
                      {state === MutationState.loading && <Spinner size="md" />}
                    </div>
                  </ModalDialogContent>
                  <ModalDialogFooter
                    showConfirm
                    showCancel={!success ? true : false}
                    confirmText={
                      !success
                        ? translate('Opt_In_Modal_Confirm')
                        : translate('Opt_In_Modal_Confirm_Success')
                    }
                    onCancel={handleCloseModal}
                    onConfirm={success ? handleCloseModal : handleAcceptRequest}
                  />
                </ModalDialog>
              </div>
            </div>
          </div>
        )}
      </>
    )
  }
  return null
}

export default OptInBanner
