import React from 'react'
import { useRouter } from 'next/router'
import { useTranslations } from '@dustin-web/microsite-components/src/hooks'
import Icon from '@dustin-web/microsite-components/src/icon'

const BannerTitle = () => {
  const { translate } = useTranslations()
  const router = useRouter()

  const subTitle = () => {
    switch (router.pathname) {
      case '/account/overview':
        return translate('Overview_heading')
      case '/orders':
        return translate('OrderHistory')
      case '/orders/products':
        return translate('OrderHistory')
      case '/orders/invoices':
        return translate('OrderHistory')
      case '/rma':
        return translate('MattersAndComplaints')
      case '/account/myaccount':
        return translate('AccountSettings')
      case '/account/changepassword':
        return translate('AccountSettings')
      case '/account/addresses':
        return translate('ManageAddresses')
      case '/account/services':
        return translate('Services_Heading')
      case '/orders/external-orders':
        return translate('OrderHistory_ExternalOrderList')
      case '/preorder':
        return translate('PreOrders_Title')
      case '/account/attestsetup':
        return translate('AttestSetup')
    }
    return ''
  }

  return (
    <div>
      <div className="d-flex align-items-center">
        <Icon name="user" className="i-40" />
        <div className="u-pl-16">
          <h2 className="u-mb-0">{translate('MyAccount')}</h2>
          {subTitle && <p className="u-mb-0">{subTitle()}</p>}
        </div>
      </div>
    </div>
  )
}

export default BannerTitle
