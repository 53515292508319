import React, { useMemo, useState } from 'react'
import Icon from '@dustin-web/microsite-components/src/icon'
import Button from '@dustin-web/microsite-components/src/button'
import { useCookieState } from '@dustin-web/microsite-components/src/hooks/use-cookie-state'
import { constants } from '@dustin-web/microsite-config'
import { useTranslations, useWebToggles } from '@dustin-web/microsite-components/src/hooks'

const UpdateContactInformationBanner = () => {
  const { translate } = useTranslations()
  const { cookies, setCookie } = useCookieState()
  const { isToggleEnabled } = useWebToggles()

  const [isToggled] = useState<boolean>(isToggleEnabled('show-encourage-user-info-update'))
  const [cookieIsSet, setCookieIsSet] = useState(!!cookies.myAccountUpdateDismissed)

  const showUpdateBanner = useMemo(() => !cookieIsSet && isToggled, [isToggled, cookieIsSet])

  const handleClick = () => {
    setCookie(constants.cookies.myAccountUpdateDismissed, true)
    setCookieIsSet(true)
  }

  if (showUpdateBanner) {
    return (
      <div className="u-bg-light-blue u-relative">
        <div className="container d-flex flex-column flex-md-row align-md-items-center u-py-16">
          <div className="u-color-dark-blue d-flex align-items-center">
            <Icon name="office-3" className="i-40 d-none d-md-block" />
            <div className="u-color-dark-blue u-pl-md-32 u-pr-md-48">
              <p className="u-fw-bold u-fs-14 u-mb-0">{translate('Update_Header')}</p>
              <p className="u-fs-14 u-mb-0">{translate('Update_Content')}</p>
            </div>
          </div>
          <div className="d-flex u-pt-16 u-pt-md-0 u-pr-md-32">
            <Button
              data-ga-title="update-info-banner-navigation"
              className="u-mb-0 u-bg-dark-blue u-color-light-blue"
              primary
              link
              href="/account/myaccount"
              text={translate('Update_Button')}
              icon={'arrow-right'}
            />
          </div>
          <button
            data-ga-title="update-info-banner-close"
            className="c-close-btn"
            style={{ zIndex: 0 }}
            aria-label="close sticky commitment footer"
            onClick={handleClick}
          />
        </div>
      </div>
    )
  }

  return null
}

export default UpdateContactInformationBanner
