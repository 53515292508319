import { FunctionComponent, useMemo } from 'react'
import { useTranslations } from '../util'
import { useResponsive } from '@dustin-web/microsite-components/src/hooks'
import React from 'react'
import Image from '@dustin-web/microsite-components/src/image'
import UpdateContactInformationBanner from './UpdateContactInformationBanner'
import InfoBanner from '../infoBanner'
import useMenuItems from 'src/hooks/useMenuItems'
import FrameAgreementBanner from '../frameAgreementBanner'
import { Breakpoints } from '@dustin-web/microsite-config/src/breakpoints'
import classNames from 'classnames'
import Moment from 'moment'
import { useGraphql } from '@dustin-web/microsite-data/src/hooks'
import { GetContactQuery } from '@dustin-web/microsite-data/src/graphql/product/generated/graphql-types'
import { GET_CONTACT } from '@dustin-web/microsite-data/src/graphql/product/get-contact.graphql'
import { useAuth } from '@dustin-web/microsite-components/src/auth'
import Link from 'next/link'
import BannerTitle from './bannerTitle'
import OptInBanner from '../optInBanner'

type Props = {
  containerClassName?: string
  children: React.ReactNode
}

const Layout: FunctionComponent<Props> = ({ children }) => {
  const { windowSize } = useResponsive()
  const { width } = windowSize
  const isLargeScreen: boolean = useMemo(() => (width ?? 0) >= Breakpoints.LG, [width])
  const { translate } = useTranslations()
  const { isAuthenticated, userData } = useAuth()

  // const { items, error, loading } = useMenuItems()

  const { data, error, isValidating } = useGraphql<GetContactQuery>(GET_CONTACT, {
    context: {
      // clientName: 'baseLayoutProductGraphQl',
      skip: !isAuthenticated,
    },
  })

  const { categoryItems } = useMenuItems({
    visibility: data?.contact?.visibility,
  })

  if (isValidating || error) {
    return null
  }

  const todayDate = Moment().format('YYYY-MM-DD')

  return (
    <div className="s-account">
      {todayDate >= '2022-12-01' && todayDate <= '2022-12-15' && <UpdateContactInformationBanner />}
      <InfoBanner />
      <FrameAgreementBanner />
      <OptInBanner />
      <div className="c-myaccount-hero u-py-40 u-py-lg-72 u-attach-section">
        <div className="container z-index-autocomplete">
          <BannerTitle />
        </div>
        <div className="hero-filter u-absolute" />
        <div className="u-absolute u-w-100 u-h-100">
          <Image
            alt="My account image"
            src={translate('MyAccount_HeroImage')}
            width={1920}
            height={180}
            style={{ height: '100%', width: '100%', objectFit: 'cover' }}
          />
        </div>
      </div>
      <div
        className={classNames('container d-flex u-pb-40', {
          'flex-column': !isLargeScreen,
        })}
      >
        {isLargeScreen && (
          <div className="col-12 col-md-3 u-pt-40 u-px-0">
            {!userData?.isPunchout && (
              <div className="u-pb-16 u-mb-40 u-fs-20">
                {categoryItems.map((categoryItem, index) => (
                  <React.Fragment key={index}>
                    <Link href={categoryItem.pageName} className="u-decoration-none u-fs-16">
                      <div
                      // className={
                      //   categoryItem.active ? `u-fw-bold u-py-8 u-fs-16` : 'u-py-8 u-fs-16'
                      // }
                      >
                        {/* <a style={{ textDecoration: 'none' }}> */}
                        <div>{categoryItem.title}</div>
                        {/* </a> */}
                      </div>
                    </Link>

                    {categoryItem.items.map((item, index) => (
                      <Link href={item.pageName} style={{ textDecoration: 'none' }} key={index}>
                        <div
                          className={
                            item.active
                              ? `u-fw-bold u-py-8 u-pl-16 u-fs-14`
                              : 'u-py-8 u-pl-16 u-fs-14'
                          }
                        >
                          {item.title}
                        </div>
                      </Link>
                    ))}
                  </React.Fragment>
                ))}
              </div>
            )}
          </div>
        )}
        <div className="col-12 col-lg-9 u-pt-40 u-px-0">{children}</div>
      </div>
    </div>
  )
}

export default Layout
