import { useTranslations } from '@dustin-web/microsite-components/src/hooks'
import { GetContactQuery } from '@dustin-web/microsite-data/src/graphql/product/generated/graphql-types'
import { useRouter } from 'next/router'

export type MenuCategoryItemType = {
  active: boolean
  pageName: string
  title: string
  items: MenuItemType[]
}
export type MenuItemVipAdminType = {
  active: boolean
  pageName: string
  title: string
  items: MenuItemType[]
}

export type MenuItemType = {
  active: boolean
  pageName: string
  title: string
}

type ContactType = NonNullable<GetContactQuery['contact']>
type VisibilityType = ContactType['visibility']

type UseMenuItemsProps = {
  visibility: VisibilityType
}

const useMenuItems = ({ visibility }: UseMenuItemsProps) => {
  const { translate } = useTranslations()
  const router = useRouter()

  const orderItems: MenuItemType[] = [
    {
      pageName: '/orders',
      title: translate('OrderHistory_OrderList'),
      active: router.pathname === '/orders',
    },
    {
      pageName: '/orders/products',
      title: translate('OrderHistory_PurchasedProducts'),
      active: router.pathname === '/orders/products',
    },
  ]

  // if (visibility?.showInvoices)
  // {
  //   orderItems.push({
  //     pageName: '/orders/invoices',
  //     title: translate('OrderHistory_Invoices'),
  //     active: router.pathname === '/orders/invoices',
  //   })
  // }
  if (visibility?.showInvoices) {
    orderItems.push({
      pageName: '/orders/invoices',
      title: translate('OrderHistory_Invoices'),
      active: router.pathname === '/orders/invoices',
    })
  }

  if (visibility?.showExternalOrders) {
    orderItems.push({
      pageName: '/orders/external-orders',
      title: translate('OrderHistory_ExternalOrderList'),
      active: router.pathname === '/orders/external-orders',
    })
  }

  const accountItems: MenuItemType[] = [
    {
      pageName: '/account/myaccount',
      title: translate('AccountSettings'),
      active: router.pathname === '/account/myaccount',
    },
    {
      pageName: '/account/changepassword',
      title: translate('ChangePassword'),
      active: router.pathname === '/account/changepassword',
    },
  ]

  const categoryItems: MenuCategoryItemType[] = [
    // {
    //   active: router.pathname === '/account/overview',
    //   title: translate('Overview_heading'),
    //   pageName: '/account/overview',
    //   items: [],
    // },
    {
      active: router.pathname === '/account/overview',
      title: translate('Overview_heading'),
      pageName: '/account/overview',
      items: [],
    },
    {
      active: orderItems.some((o) => o.pageName === router.pathname),
      title: translate('OrderHistory'),
      pageName: '/orders',
      items: orderItems,
    },
    {
      active: router.pathname === '/rma',
      title: translate('MattersAndComplaints'),
      pageName: '/rma',
      items: [],
    },
    {
      active: accountItems.some((o) => o.pageName === router.pathname),
      title: translate('AccountSettings'),
      pageName: '/account/myaccount',
      items: accountItems,
    },
  ]

  if (visibility?.showAttest || visibility?.showPreOrder) {
    categoryItems.push({
      active: router.pathname === '/preorder',
      title: translate('PreOrders_Title'),
      pageName: '/preorder',
      items: [],
    })
  }

  if (visibility?.showAddresses) {
    categoryItems.push({
      active: router.pathname === '/account/addresses',
      title: translate('ManageAddresses'),
      pageName: '/account/addresses',
      items: [],
    })
  }

  if (visibility?.showContacts) {
    categoryItems.push({
      active: router.pathname === '/account/contacts',
      title: translate('ManageContacts'),
      pageName: '/account/contacts',
      items: [],
    })
  }

  if (visibility?.showAttest) {
    categoryItems.push({
      active: router.pathname === '/account/attestsetup',
      title: translate('AttestSetup'),
      pageName: '/account/attestsetup',
      items: [],
    })
  }

  if (visibility?.showServices) {
    categoryItems.push({
      active: router.pathname === '/account/services',
      title: translate('Services_Heading'),
      pageName: '/account/services',
      items: [],
    })
  }

  return { categoryItems }
}

export default useMenuItems
