import React, { useState } from 'react'
import { useTranslations, useWebToggles } from '@dustin-web/microsite-components/src/hooks'
import { NotificationBanner } from '@dustin-web/microsite-components/src/banners'

const InfoBanner = () => {
  const { translate } = useTranslations()
  const { isToggleEnabled } = useWebToggles()

  const [isToggled] = useState<boolean>(isToggleEnabled('show-myaccount-info-banner'))
  const [show, setShow] = useState(true)
  const buttonText = translate('Info_Banner_ButtonText')
  const ctaButton = buttonText !== '' ? true: false
  if (isToggled && show) {
    return (
      <NotificationBanner 
      type='INFO'
      title={translate('Info_Banner_Title')}
      description={translate('Info_Banner_Content')}
      fullWidth
      closeable
      onClose={() => setShow(!show)}
      ctaButton = {ctaButton}
      buttonText = {buttonText}
      href={'Info_Banner_ButtonLink'}
      />
    )
  }
  return null
}

export default InfoBanner
